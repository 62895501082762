import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import navigation from './modules/navigation/actionSideNav';
import dataTable from './modules/data-filter/actionCreator';
import users from './modules/users/actionCreator';

export default createStore({
  modules: {
    dataTable,
    themeLayout,
    auth,
    navigation,
    users
  },
});
