import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
import '@/core/plugins/unicons';
import '@/core/components/custom';
import '@/core/plugins/apexcharts';
// import '@/core/components/style';
import '@/i18n/config';
import CKEditor from '@ckeditor/ckeditor5-vue';


app.config.productionTip = false;
app.use(CKEditor);
app.use(store);
app.use(router);
app.mount('#app');