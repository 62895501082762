
const state = {
  sideNavItems: [
    {
      isNav: true,
      title: 'Dashboard',
      slug: 'dashboard',
      icon: 'create-dashboard', 
      path: '/',
      module: 'dashboard',
      children: [],
    },
    {
      isNav: false,
      title: 'Pages',
      module: 'Basic Pages',
    },
    {
      isNav: true,
      title: 'All Pages',
      slug: 'all-pages',
      module: 'Basic Pages',
      icon: 'browser', 
      children: [],
      path: '/all-pages',
    },
    {
      isNav: true,
      title: 'Navigation',
      slug: 'top-navigation',
      module: 'Basic Pages',
      icon: 'navigator', 
      children: [],
      path: '/top-navigation',
    },
    {
      isNav: true,
      title: 'Footer',
      slug: 'all-pages-footer',
      module: 'Basic Pages',
      icon: 'navigator', 
      children: [],
      path: '/all-pages/footer',
    },
    {
      isNav: false,
      title: 'Products',
      module: 'Products',
    },
    {
      isNav: true,
      title: 'All Products',
      slug: 'all-products',
      module: 'Products',
      icon: 'briefcase', 
      children: [],
      path: '/all-products',
    },
    {
      isNav: true,
      title: 'Categories',
      slug: 'categories',
      module: 'Products',
      icon: 'tag-alt', 
      children: [],
      path: '/categories',
    },
    {
      isNav: true,
      title: 'Testimonials',
      slug: 'testimonials',
      module: 'Testimonials',
      icon: 'book-open', 
      children: [],
      path: '/list-testimonials',
    },
    {
      isNav: false,
      title: 'Blog',
      module: 'Blogs',
    },
    {
      isNav: true,
      title: 'All Posts',
      slug: 'all-posts',
      module: 'Blogs',
      icon: 'newspaper', 
      children: [],
      path: '/all-posts',
    },
    {
      isNav: true,
      title: 'Categories',
      slug: 'all-blog-categories',
      module: 'Blogs',
      icon: 'tag-alt', 
      children: [],
      path: '/all-blog-categories',
    },
    {
      isNav: true,
      title: 'Tags',
      slug: 'all-blog-tags',
      module: 'Blogs',
      icon: 'tag-alt', 
      children: [],
      path: '/all-blog-tags',
    },
    {
      isNav: false,
      title: 'File Manager',
      module: 'File Manager',
    },
    {
      isNav: true,
      title: 'File Manager',
      slug: 'file-manager',
      module: 'File Manager',
      icon: 'folder-download', 
      children: [],
      path: '/file-manager',
    },
    {
      isNav: false,
      title: 'Reports',
      module: 'Reports',
    },
    {
      isNav: true,
      title: 'Generated Quotations',
      slug: 'generated-quotations',
      module: 'Reports',
      icon: 'usd-circle', 
      children: [],
      path: '/reports/quotations',
    },
    {
      isNav: true,
      title: 'Inquiries',
      slug: 'inquiries',
      module: 'Reports',
      icon: 'mailbox', 
      children: [],
      path: '/reports/inquiries',
    },
    {
      isNav: true,
      title: 'Activity Logs',
      slug: 'activity-logs',
      module: 'Reports',
      icon: 'history', 
      children: [],
      path: '/reports/activity-logs',
    },
    {
      isNav: true,
      title: 'Error Logs',
      slug: 'error-logs',
      module: 'Reports',
      icon: 'exclamation-triangle', 
      children: [],
      path: '/reports/error-logs',
    },
    {
      isNav: false,
      title: 'User Management',
      module: 'Users',
    },
    {
      isNav: true,
      title: 'All Users',
      slug: 'all-users',
      module: 'Users',
      icon: 'users-alt', 
      children: [],
      path: '/all-users',
    },
    {
      isNav: true,
      title: 'Roles & Permissions',
      slug: 'roles-permissions',
      module: 'Users',
      icon: 'exclamation-triangle', 
      children: [],
      path: '/roles-permissions',
    },
  ],
};

// Mutations to update the state
const mutations = {
  SET_SIDE_NAV_ITEMS(state, items) {
    state.sideNavItems = items;
  },
};

// Actions to perform asynchronous operations
const actions = {
  updateSideNavItems({ commit }, items) {
    commit('SET_SIDE_NAV_ITEMS', items);
  },
};

// Getters to access the state
const getters = {
  sideNavItems: (state) => state.sideNavItems,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

