import { meta } from "./meta";
const routes = [
  {
    path: '/auth/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
    meta: {
      ...meta['login']
    }
  },
  {
    path: '/auth/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/Signup.vue'),
    meta: {
      ...meta['register']
    }
  },
  {
    path: '/auth/forgot-password',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
    meta: {
      ...meta['forgot-password']
    }
  },
  {
    path: '/auth/update-password',
    name: 'update-password',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/UpdatePassword.vue'),
    meta: {
      ...meta['update-password']
    }
  },
];

export default routes;
