
const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Dashboard/DashBoardLanding.vue'),
  },
  {
    path: '/reports/quotations',
    name: 'quotations-reports',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Dashboard/Reports/QuotationsReport/Index.vue'),
  },
  {
    path: '/reports/quotations/products',
    name: 'quotations-products',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Dashboard/Reports/QuotationsReport/SeeMoreProducts.vue'),
  },
  {
    path: '/reports/inquiries',
    name: 'inquiries',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Dashboard/Reports/InquiriesReport/Index.vue'),
  },
  {
    path: '/reports/error-logs',
    name: 'error-logs',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Dashboard/Reports/ErrorLogs/Index.vue'),
  },
  {
    path: '/reports/activity-logs',
    name: 'activity-logs',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Dashboard/Index.vue'),
  },
  // User Management
  {
    path: '/all-users',
    name: 'all-users',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/ListUser.vue'),
  },
  {
    path: '/all-users/create',
    name: 'all-users-create',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/CreateUser.vue'),
  },
  {
    path: '/all-users/update',
    name: 'all-users-update',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/UpdateUser.vue'),
  },
  {
    path: '/all-users/view',
    name: 'all-users-view',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/ViewUser.vue'),
  },
  {
    path: '/roles-permissions',
    name: 'roles-permissions',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/RolePermission/ListRolePermission.vue'),
  },
  {
    path: '/roles-permissions/view',
    name: 'view-role-permissions',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/RolePermission/ViewRolePermission.vue'),
  },
  {
    path: '/roles-permissions/create',
    name: 'create-role-permissions',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/RolePermission/CreateRolePermission.vue'),
  },
  // List of Pages
  {
    path: '/all-pages',
    name: 'all-pages',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/ListPage.vue'),
  },
  {
    path: '/all-pages/create',
    name: 'all-pages-create',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/SetUpPage.vue'),
  },
  {
    path: '/all-pages/update',
    name: 'all-pages-update',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/UpdatePage.vue'),
  },
  {
    path: '/all-pages/view',
    name: 'all-pages-view',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/ViewPage.vue'),
  },
  {
    path: '/all-pages/page-content',
    name: 'page-content',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/PageContent/PageListContent.vue'),
  },
  {
    path: '/top-navigation',
    name: 'page-content',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/TopNav/Index.vue'),
  },
  
  // Page Footer
  {
    path: '/all-pages/footer',
    name: 'all-pages-footer',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/Footer/Footer.vue'),
  },
  {
    path: '/all-pages/footer/update',
    name: 'all-pages-footer-update',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Page/Footer/UpdateFooter.vue'),
  },
  {
    path: '/file-manager',
    name: 'file-managent',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/FileManagement/Index.vue'),
  },
  // Products & Categories
  {
    path: '/all-products',
    name: 'all-products',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/ListProducts.vue'),
  },
  {
    path: '/all-products/create',
    name: 'all-products-create',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/CreateProduct.vue'),
  },
  {
    path: '/all-products/update',
    name: 'all-products-update',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/UpdateProduct.vue'),
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Categories/ListCategories.vue'),
  },
  {
    path: '/categories/create',
    name: 'categories-create',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Categories/CreateCategories.vue'),
  },
  {
    path: '/categories/update',
    name: 'categories-update',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Categories/UpdateCategories.vue')
  },
  {
    path: '/roles-permissions/update',
    name: 'update-role-permissions',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/UserManagement/RolePermission/UpdateRolePermission.vue'),
  },
  // Inquiry Form
  {
    path: 'product/inquiry-form-setting',
    name: 'inquiry-form-setting',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/InquiryFormSetting.vue'),
  },
  // Testimonials
  {
    path: '/list-testimonials',
    name: 'list-testimonials',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Testimonials/ListTestimonials.vue'),
  },
  {
    path: '/list-testimonials/create',
    name: 'list-testimonials-create',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Testimonials/CreateTestimonials.vue'),
  },
  {
    path: '/list-testimonials/update',
    name: 'list-testimonials-update',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Testimonials/UpdateTestimonials.vue'),
  },
  {
    path: '/list-testimonials/view',
    name: 'list-testimonials-view',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Product/Testimonials/ViewTestimonials.vue'),
  },
  // File Management

  // Blogs
  {
    path: '/all-posts',
    name: 'all-posts',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/AllPost.vue'),
  },
  {
    path: '/all-posts/create',
    name: 'all-create-post',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/CreateBlogPage.vue'),
  },
  {
    path: '/all-blog-categories',
    name: 'all-blog-categories',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/Category/AllCategory.vue'),
  },
  {
    path: '/all-blog-categories/create',
    name: 'create-categories',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/Category/CreateCategory.vue'),
  },
  {
    path: '/all-blog-categories/update',
    name: 'update-categories',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/Category/UpdateCategory.vue'),
  },
  {
    path: '/all-blog-tags',
    name: 'all-blog-tags',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/Tags/AllTags.vue'),
  },
  {
    path: '/all-blog-tags/create',
    name: 'create-tags',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/Tags/CreateTags.vue'),
  },
  {
    path: '/all-blog-tags/update',
    name: 'update-tags',
    component: () => import(/* webpackChunkName: "Blank" */ '@/view/pages/Blogs/Tags/UpdateTags.vue'),
  },
  
];

export default routes;
